.DateRangePicker_picker,
.CalendarMonth,
.CalendarMonthGrid,
.DayPickerNavigation_button,
.DateRangePickerInput,
.DateInput_input {
    background-color: var(--picker-background, #fff);
}

.DayPickerNavigation_button {
    border-color: var(--picker-outline, #fff);
    color: var(--picker-color, #fff);
}

.DateRangePicker_picker {
    z-index: 10000;
    height: 100%;
}
/* Mobile View portal */
.DayPicker_transitionContainer {
   background-color: inherit;
}

.DayPicker {
   background-color: inherit;
}
 
.DateRangePickerInput__withBorder {
    border-radius: 10px;
}
  
.DateInput_input {
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.firebase-emulator-warning {
    display: none;
}

.CalendarMonth_table {
    border-collapse: unset;
    border-spacing: 1px;
}

.CalendarMonth_caption,
.DateInput_input,
.DateRangePickerInput_arrow,
.DateRangePickerInput_arrow_svg,
.DateInput_input__readOnly::placeholder {
    color: var(--picker-color);
    fill: var(--picker-color)
}
