:root {
  --banner-common-white: #fff;
  --banner-warning-light: #ffbb00;
  --banner-primary-light: #5BE584;
  --banner-error-main: #FF5630;
  --banner-text-primary: #212B36;
  --bannertext-disabled: #919EAB;
  --banner-disabled-opacity: 0.48
}
/* -------------------- */

.banner-text {
  font-size: 2.0em;
}
.banner-text-color-primary {
  color: var(--banner-text-primary) !important
}
.banner-text-color-white, .MuiBadge-badge {
  color: var(--banner-common-white) !important;
}

.banner-uncollapsed {
  background-color: var(--banner-common-white) !important;
}
.banner-top-margin {
  top: 50px; /* Ohana only positioning */
}

.banner-priority-high {
  background-color: var(--banner-error-main) !important;
  /* border-bottom: 1px solid var(--error-main) !important; */
}

.banner-priority-medium {
  background-color: var(--banner-warning-light) !important;
  /* border-bottom: 1px solid var(--warning-light) !important; */
}

.badge-dot_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-icon * {
  height: 35px !important;
  width: 35px !important;
}